import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import JsonData from "../app/data/normal.json";
import JsonData1 from "../app/data/normalNotices.json";
import user from "../app/data/user.json";
import BarChart from "../app/chats/BarChart";
import StackedBarChart from "../app/chats/StackedBarChart";
import StackedBarChartNew from "../app/chats/StackedBarChartNew";
import { Link } from "react-router-dom/cjs/react-router-dom";
import Loading from "../app/Loading/Loading";
import Select from "react-select";
import { useOktaAuth } from "@okta/okta-react";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const NormalNotices = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState();

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
    // console.log("User Login Success");		 
      setUserInfo(authState.idToken.claims);
      const fetchData = authState.idToken.claims
      const email = fetchData.email
      console.log(email)
      if(email === 'support@datadekho.in'){
        setData(JsonData1)
        setFilteredData(JsonData1)
      }
      else{

        const targetUser = userData.find(user => user.email === email);
        if(targetUser){
          const filteredNotices = JsonData.filter(notice =>
            targetUser.company.includes(notice.Company) &&
            targetUser.state.includes(`${notice.Company}_${notice.state.replace(/\s+/g, '_')}`)
        );
        console.log(filteredNotices)
          setData(filteredNotices)
          setFilteredData(filteredNotices)
        }
        else{
          setData(JsonData)
          setFilteredData(JsonData)
  
        }
      }
    }
  }, [authState, oktaAuth]);
  const [data, setData] = useState([]);
  const [userData,setuserData] = useState(user)
  const [filteredData, setFilteredData] = useState([]);
  const [company, setCompany] = useState("");
  const [Year, setYear] = useState("");
  const [Month, setMonth] = useState("");
  const [State, setState] = useState("");
  const [Type, setType] = useState("");
  const [CaseID, setCaseID] = useState("");
  const [Status, setStatus] = useState("");
  const [Sidenav, setSidenav] = useState(true);
  const [loading, setloading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    // Apply filters whenever the filter criteria change
    const filtered = search(data);
    setFilteredData(filtered);
  }, [company, Year, Month, State, Type, CaseID, Status, data]);

  const FilterByState = filteredData.map((item) => item.state);
  // const FilterByYear = data.map((item) => item['Date Of Application/Case Creation'])

  const UniqueFilterByState = [...new Set(FilterByState)];
  UniqueFilterByState.sort((a, b) =>
    a.localeCompare(b, undefined, { sensitivity: "base" })
  );


  const FilterByYear = data.map((item) => item.Year);

  const FilterByMonth = data.map((item) => item.Month);

  const UniqueFilterByMonth = [...new Set(FilterByMonth)];

  function sortMonths(monthArray) {
    let correctOrder = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthArray.sort(
      (a, b) => correctOrder.indexOf(a) - correctOrder.indexOf(b)
    );
  }

  let sortedMonths = sortMonths(UniqueFilterByMonth);

  const UniqueFilterByYearsData = [...new Set(FilterByYear)];
  UniqueFilterByYearsData.sort((a, b) => a - b);

  const FilterByCompany = data.map((item) => item.Company);
  const UniqueFilterByCompany = [...new Set(FilterByCompany)];

  const FilterByStatus = data.map((item) => item.Status);
  const UniqueFilterByStatus = [...new Set(FilterByStatus)];

  console.log(FilterByStatus)

  const FilterByType = data.map((item) => item.Type);
  const UniqueFilterByType = [...new Set(FilterByType)];

  const FilterByCase = data.map((item) => item["Case ID"]);
  const UniqueFilterByCase = [...new Set(FilterByCase)];

  let filteredDataNew = [];

  if (data !== null) {
    filteredDataNew = data.filter((obj) => {
      return obj;
    });
  }

  function search(data) {
    return data.filter((item) => {
      const companyMatch =
        company.length === 0 ||
        company.some((selected) => item.Company.includes(selected));
      const yearMatch =
        Year.length === 0 ||
        Year.some((selected) => item.Year.includes(selected));
      const monthMatch =
        Month.length === 0 ||
        Month.some((selected) => item.Month.includes(selected));
      const stateMatch =
        State.length === 0 ||
        State.some((selected) => item.state.includes(selected));
      const caseIDMatch =
        CaseID.length === 0 ||
        CaseID.some((selected) => item["Case ID"].includes(selected));
      const TypeMatch =
        Type.length === 0 ||
        Type.some((selected) => item.Type.includes(selected));
      const statusMatch =
        Status.length === 0 ||
        Status.some((selected) => item.Status.includes(selected));

      return (
        companyMatch &&
        yearMatch &&
        monthMatch &&
        stateMatch &&
        caseIDMatch &&
        TypeMatch &&
        statusMatch
      );
    });
  }


  const StateWiseData = search(data).reduce((acc, item) => {
    const month = item.Month;
    if (!acc[month]) {
      acc[month] = 0;
    }
    acc[month]++;
    return acc;
  }, {});

  const TypeOfNotice = {};

  search(data).forEach((item) => {
    const key = `${item.Company}_${item.Year}`;
    if (!TypeOfNotice[key]) {
      TypeOfNotice[key] = [];
    }
    if (!TypeOfNotice[key].includes(item["Case ID"])) {
      TypeOfNotice[key].push(item["Case ID"]);
    }
  });

  // const result = Object.entries(TypeOfNotice).reduce((acc, [key, value]) => {
  //   const company = key.split("_")[0];
  //   const year = key.split("_")[1];
  //   const count = value.length;
  //   const existingCompany = acc.find((item) => item.name === company);
  //   if (existingCompany) {
  //     existingCompany.data.push(count);
  //   } else {
  //     acc.push({ name: company, data: [count] });
  //   }
  //   return acc;
  // }, []);

  const result = Object.entries(TypeOfNotice).reduce((acc, [key, value]) => {
    const company = key.split("_")[0];
    const year = parseInt(key.split("_")[1]); // Extract year and convert to number

    // Calculate count based on the length of the array
    const count = value.length;

    // Find existing company data in accumulator
    const existingCompany = acc.find((item) => item.name === company);

    if (existingCompany) {
      // Check if the year already exists in data, if not add it
      const existingYearData = existingCompany.data.find((item) => item.year === year);
      if (existingYearData) {
        existingYearData.count += count;
      } else {
        existingCompany.data.push({ year, count });
      }
    } else {
      // If company does not exist in accumulator, create new entry
      acc.push({ name: company, data: [{ year, count }] });
    }

    return acc;
  }, []);
  
  const TypeOfNoticeYear = {};

  search(data).forEach((item) => {
    const key = `${item.Company}_${item.Year}`;
    if (!TypeOfNoticeYear[key]) {
      TypeOfNoticeYear[key] = [];
    }
    if (!TypeOfNoticeYear[key].includes(item["Case ID"])) {
      TypeOfNoticeYear[key].push(item["Case ID"]);
    }
  });

  const resultTypeOfNoticeYear = Object.entries(TypeOfNoticeYear).map(
    ([key, value]) => ({
      company: key.split("_")[0],
      year: key.split("_")[1],
      count: value.length,
    })
  );

  const StateWiseAnalysis = {};
  search(data).forEach((item) => {
    if (!StateWiseAnalysis[item.state]) {
      StateWiseAnalysis[item.state] = new Set();
    }
    StateWiseAnalysis[item.state].add(item["Case ID"]);
  });

  const resultStateWise = Object.entries(StateWiseAnalysis).map(
    ([name, cases]) => ({
      name,
      count: cases.size,
    })
  );

  const StatueWiseAnalysis = {};
  search(data).forEach((item) => {
    if (!StatueWiseAnalysis[item.Status]) {
      StatueWiseAnalysis[item.Status] = new Set();
    }
    StatueWiseAnalysis[item.Status].add(item["Case ID"]);
  });

  const resultStatusWise = Object.entries(StatueWiseAnalysis).map(
    ([name, cases]) => ({
      name,
      count: cases.size,
    })
  );

  const caseIDMap = {};

  search(data).forEach((item) => {
    const company = item.Company;
    if (!caseIDMap[company]) {
      caseIDMap[company] = new Set();
    }
    caseIDMap[company].add(item["Case ID"]);
  });

  // Step 2: Calculate the total number of distinct Case IDs
  const totalDistinctCaseIDs = Object.values(caseIDMap).reduce(
    (total, caseIDs) => total + caseIDs.size,
    0
  );

  // Step 3: Map the object to the desired format with percentages
  const CountOfCaseID = Object.entries(caseIDMap).map(([company, caseIDs]) => {
    const count = caseIDs.size;
    const percentage = (count / totalDistinctCaseIDs) * 100;
    return {
      name: company,
      data: [percentage.toFixed(2)], // Round to 2 decimal places
    };
  });

  // Define an array of month names in the correct order
  const monthsInOrder = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // Create an array to hold the formatted month counts
  const formattedStateWiseData = [];

  // Populate the formatted month counts
  monthsInOrder.forEach((name) => {
    const count = StateWiseData.hasOwnProperty(name) ? StateWiseData[name] : 0;
    formattedStateWiseData.push({ name, count });
  });

  const AmountDemand = search(data)
  .map((item) => item['Amount of Demand'])
  .filter((amount) => amount !== '')
  .reduce((total, amount) => total + parseFloat(amount), 0)

  const formattedAmountDemand = Math.round(AmountDemand).toLocaleString();

  return (
    <div id="normal-homePage" style={{ overflow: "hidden" }}>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "hidden" }}>
        <MainLayout dataItems={data} setCompany={setCompany}/>

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHome" style={{overflowY:"auto"}}>
            {loading ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                {!data && <>Loading...</>}

                {data && (
                  <div style={{position:"relative"}}>

<span style={{position:"absolute",right:"0px",top:"70px"}} class="subHeaderText">
                          Last Refresh on 14th Sept 2024
                        </span>
                    <div class="headerItems">
                      <div>
                        <h2 class="headerText">GST Notice Dekho</h2>
                        <p class="subHeaderText">
                          View Normal Notices and Orders
                        </p>
                      </div>
                      <div class="main-section-1">
                        <Link to="/">
                          <div class="custom-Total-case-id-2">
                            Click here to view Additional Notices and Orders
                          </div>
                        </Link>

                        <div class="custom-Total-case-id ">
                          Total Case ID : {totalDistinctCaseIDs}
                        </div>

                        <div class="custom-Total-case-id ">
                        Amount Demand : {formattedAmountDemand}
                        </div>

                        
                      </div>
                    </div>

                    <div class="main-section-2">
                      <div>
                      <div class="section-class-2-grid-layout">
                        
                        <div class="form-group">
                          <label class="company" htmlFor="company">
                            Company
                          </label>
                          <Select
                            isMulti
                            options={UniqueFilterByCompany.map((item) => ({
                              value: item,
                              label: item,
                            }))}
                            onChange={(selectedOptions) =>
                              setCompany(
                                selectedOptions.map((option) => option.value)
                              )
                            }
                            placeholder="All"
                          />
                        </div>

                        <div className="form-group">
                          <label className="Year" htmlFor="Year">
                            Year
                          </label>
                          <Select
                            isMulti
                            options={UniqueFilterByYearsData.map((item) => ({
                              value: item,
                              label: item,
                            }))}
                            onChange={(selectedOptions) =>
                              setYear(
                                selectedOptions.map((option) => option.value)
                              )
                            }
                            placeholder="All"

                          />
                        </div>

                        <div className="form-group">
                          <label className="Month" htmlFor="Month">
                            Month
                          </label>
                          <Select
                            isMulti
                            options={UniqueFilterByMonth.map((item) => ({
                              value: item,
                              label: item,
                            }))}
                            onChange={(selectedOptions) =>
                              setMonth(
                                selectedOptions.map((option) => option.value)
                              )
                            }
                            placeholder="All"

                          />
                        </div>

                        <div className="form-group">
                          <label className="State" htmlFor="State">
                            State
                          </label>
                          <Select
                            isMulti
                            options={UniqueFilterByState.map((item) => ({
                              value: item,
                              label: item,
                            }))}
                            onChange={(selectedOptions) =>
                              setState(
                                selectedOptions.map((option) => option.value)
                              )
                            }
                            placeholder="All"

                          />
                        </div>

                        <div className="form-group">
                          <label className="Status" htmlFor="Status">
                            Type
                          </label>
                          <Select
                            isMulti
                            options={UniqueFilterByType.map((item) => ({
                              value: item,
                              label: item,
                            }))}
                            onChange={(selectedOptions) =>
                              setType(
                                selectedOptions.map((option) => option.value)
                              )
                            }
                            placeholder="All"

                          />
                        </div>

                        <div className="form-group">
                          <label className="RefID" htmlFor="RefID">
                            Status
                          </label>
                          <Select
                            isMulti
                            options={UniqueFilterByStatus.map((item) => ({
                              value: item,
                              label: item,
                            }))}
                            onChange={(selectedOptions) =>
                              setStatus(
                                selectedOptions.map((option) => option.value)
                              )
                            }
                            placeholder="All"

                          />
                        </div>

                        <div className="form-group">
                          <label className="caseID" htmlFor="caseID">
                            Case ID
                          </label>
                          <Select
                            isMulti
                            options={FilterByCase.map((item) => ({
                              value: item,
                              label: item,
                            }))}
                            onChange={(selectedOptions) =>
                              setCaseID(
                                selectedOptions.map((option) => option.value)
                              )
                            }
                            placeholder="All"

                          />
                        </div>
                      </div>
                      </div>

                      <div>
                        <StackedBarChartNew CountOfCaseID={CountOfCaseID} />
                      </div>

                      <div class="main-section-3">
                        <div class="main-section-4">
                          <div class="main-section-5"> State Wise Analysis</div>
                          <BarChart
                            sorting={true}
                            isFunnel={false}
                            datalist={resultStateWise}
                            horizontal={true}
                            id="bar-chart-1"
                          />
                        </div>

                        <div class="main-section-4">
                          <div class="main-section-5">
                            {" "}
                            Company Wise Analysis
                          </div>
                          <StackedBarChart
                            resultTypeOfNoticeYear={resultTypeOfNoticeYear}
                            result={result}
                          />
                        </div>

                        <div class="main-section-4">
                          <div class="main-section-5"> Status</div>
                          <BarChart
                            sorting={true}
                            isFunnel={true}
                            datalist={resultStatusWise}
                            horizontal={true}
                            id="bar-chart-2"
                          />
                        </div>

                        <div class="main-section-4">
                          <div class="main-section-5"> Month Wise </div>
                          <BarChart
                            sorting={false}
                            isFunnel={false}
                            datalist={formattedStateWiseData}
                            horizontal={false}
                            id="bar-chart-3"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default NormalNotices;
