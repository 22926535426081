import React, { useEffect, useRef, useState } from "react";
import MainLayout from "../Layout/MainLayout";
import Box from "@mui/material/Box";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Rating from "@mui/material/Rating";
import SentimentVeryDissatisfiedIcon from "@mui/icons-material/SentimentVeryDissatisfied";
import SentimentDissatisfiedIcon from "@mui/icons-material/SentimentDissatisfied";
import SentimentSatisfiedIcon from "@mui/icons-material/SentimentSatisfied";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAltOutlined";
import SentimentVerySatisfiedIcon from "@mui/icons-material/SentimentVerySatisfied";

import JsonData from "../app/data/additional.json";
import JsonData1 from "../app/data/advancedNotices.json";
import user from "../app/data/user.json";

import { Link } from "react-router-dom/cjs/react-router-dom";
import ReportGenerate from "../reportGenerate/ReportGenerate";
import Loading from "../app/Loading/Loading";
import $ from "jquery";
import ReactPaginate from "react-paginate";
import { FaSortAlphaDown, FaSortAlphaUp } from "react-icons/fa";
import Select from "react-select";
import { useOktaAuth } from "@okta/okta-react";

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-start",
  padding: theme.spacing(2, 0),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const StyledRating = styled(Rating)(({ theme }) => ({
  "& .MuiRating-iconEmpty .MuiSvgIcon-root": {
    color: theme.palette.action.disabled,
  },
}));

const customIcons = {
  1: {
    icon: (
      <SentimentVeryDissatisfiedIcon sx={{ fontSize: "34px" }} color="error" />
    ),
    label: "Very Dissatisfied",
  },
  2: {
    icon: <SentimentDissatisfiedIcon sx={{ fontSize: "34px" }} color="error" />,
    label: "Dissatisfied",
  },
  3: {
    icon: <SentimentSatisfiedIcon sx={{ fontSize: "34px" }} color="warning" />,
    label: "Neutral",
  },
  4: {
    icon: (
      <SentimentSatisfiedAltIcon sx={{ fontSize: "34px" }} color="success" />
    ),
    label: "Satisfied",
  },
  5: {
    icon: (
      <SentimentVerySatisfiedIcon sx={{ fontSize: "34px" }} color="success" />
    ),
    label: "Very Satisfied",
  },
};

function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired,
};

const Home = () => {
  const { authState, oktaAuth } = useOktaAuth();
  const [userInfo, setUserInfo] = useState();
  const [userEmail,setuseremail] = useState("")

  useEffect(() => {
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
    } else {
    // console.log("User Login Success");		 
      setUserInfo(authState.idToken.claims);
      const fetchData = authState.idToken.claims
      const email = fetchData.email
      console.log(email)
      setuseremail(email)
      if(email === 'support@datadekho.in'){
        setData(JsonData1)
        setFilteredData(JsonData1)
      }
      else{
        const targetUser = userData.find(user => user.email === email);
        if(targetUser){
          const filteredNotices = JsonData.filter(notice =>
            targetUser.company.includes(notice.Company) &&
            targetUser.state.includes(`${notice.Company}_${notice.state.replace(/\s+/g, '_')}`)
        );
        console.log(filteredNotices)
          setData(filteredNotices)
          setFilteredData(filteredNotices)
        }
        else{
          setData(JsonData)
          setFilteredData(JsonData)
  
        }
        
      }
    }
  }, [authState, oktaAuth]);

  
  

  const [data, setData] = useState([]); // Original data
  const [userData,setuserData] = useState(user)
  const [filteredData, setFilteredData] = useState([]); // Filtered data
  const [sortOrder, setSortOrder] = useState("asc");
  const [company, setCompany] = useState("");
  const [Year, setYear] = useState("");
  const [Month, setMonth] = useState("");
  const [State, setState] = useState("");
  const [RefID, setRefID] = useState("");
  const [CaseID, setCaseID] = useState("");
  const [Status, setStatus] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPagelimit, setPageLimit] = useState(20);
  const [loading, setLoading] = useState(true);
  const [sortOrderState, setSortOrderState] = useState("asc"); // New state for state sort order
  const [OldnewData, setOldnewData] = useState(""); // New state for state sort order
  const [filteredEmails, setFilteredEmails] = useState([]);

  
  
 



  const [sortOrders, setSortOrders] = useState({
    Company: "asc",
    state: "asc", // Assuming 'state' is the correct field name for sorting by state
    "Case ID": "asc",
    Status: "asc",
    "Date of Issuance": "asc",
    "Type of Notice/Order": "asc",
    Description: "asc",
  });
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  useEffect(() => {
    // Apply filters whenever the filter criteria change
    const filtered = search(data);
    setFilteredData(filtered);
  }, [company, Year, Month, State, RefID, CaseID, Status, data,OldnewData]);

  const FilterByState = filteredData.map((item) => item.state);
  const UniqueFilterByState = [...new Set(FilterByState)];
  UniqueFilterByState.sort((a, b) =>
    a.localeCompare(b, undefined, { sensitivity: "base" })
  );

  const FilterByYear = data.map((item) => item.Year);
  const FilterByMonth = data.map((item) => item.Month);
  const UniqueFilterByMonth = [...new Set(FilterByMonth)];

  function sortMonths(monthArray) {
    let correctOrder = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    return monthArray.sort(
      (a, b) => correctOrder.indexOf(a) - correctOrder.indexOf(b)
    );
  }

  let sortedMonths = sortMonths(UniqueFilterByMonth);

  const UniqueFilterByYearsData = [...new Set(FilterByYear)];
  UniqueFilterByYearsData.sort((a, b) => a - b);

  const FilterByCompany = data.map((item) => item.Company);
  const UniqueFilterByCompany = [...new Set(FilterByCompany)];

  const oldnew = ['old','new']

  const FilterByStatus = data.map((item) => item.Status);
  const UniqueFilterByStatus = [...new Set(FilterByStatus)];

  const FilterByRef = data.map((item) => item["Ref ID"]);
  const UniqueFilterByRef = [...new Set(FilterByRef)];

  const FilterByCase = data.map((item) => item["Case ID"]);
  const UniqueFilterByCase = [...new Set(FilterByCase)];

  let filteredDataView = [];

  if (filteredData !== null) {
    filteredDataView = filteredData.filter((obj) => {
      return obj;
    });
  }

  function search(data) {
    return data.filter((item) => {
      const companyMatch =
        company.length === 0 ||
        company.some((selected) => item.Company.includes(selected));
      const yearMatch =
        Year.length === 0 ||
        Year.some((selected) => item.Year.includes(selected));
      const monthMatch =
        Month.length === 0 ||
        Month.some((selected) => item.Month.includes(selected));
      const stateMatch =
        State.length === 0 ||
        State.some((selected) => item.state.includes(selected));
      const caseIDMatch =
        CaseID.length === 0 ||
        CaseID.some((selected) => item["Case ID"].includes(selected));
      const refIDMatch =
        RefID.length === 0 ||
        RefID.some((selected) => item["Ref ID"].includes(selected));
      const statusMatch =
        Status.length === 0 ||
        Status.some((selected) => item.Status.includes(selected));
      const oldnew =
        OldnewData.length === 0 ||
        OldnewData.some((selected) => item.old_new.includes(selected));

      return (
        companyMatch &&
        yearMatch &&
        monthMatch &&
        stateMatch &&
        caseIDMatch &&
        refIDMatch &&
        statusMatch &&
        oldnew
      );
    });
  }

  let NUM_OF_RECORDS = filteredDataView.length;
  if (NUM_OF_RECORDS <= currentPagelimit) {
    $(".pagination").hide(true);
  } else {
    $(".pagination").show(true);
  }

  const currentData = filteredDataView.slice(
    (currentPage - 1) * currentPagelimit,
    (currentPage - 1) * currentPagelimit + currentPagelimit
  );

  const pageCount = Math.ceil(filteredDataView.length / currentPagelimit);

  const handelepageclick = (data) => {
    setCurrentPage(data.selected + 1);
  };

  const StateWiseData = filteredDataView.reduce((acc, item) => {
    const month = item.Month;
    if (!acc[month]) {
      acc[month] = 0;
    }
    acc[month]++;
    return acc;
  }, {});

  const handlePageChange = ({ selected }) => {
    setCurrentPage(selected);
  };

  const resetAll = () => {
    handlePageChange({ selected: 1 });
    setStatus("");
  };

  const TypeOfNotice = {};

  filteredDataView.forEach((item) => {
    const key = `${item.Company}_${item.Year}`;
    if (!TypeOfNotice[key]) {
      TypeOfNotice[key] = [];
    }
    if (!TypeOfNotice[key].includes(item["Case ID"])) {
      TypeOfNotice[key].push(item["Case ID"]);
    }
  });

  const result = Object.entries(TypeOfNotice).reduce((acc, [key, value]) => {
    const company = key.split("_")[0];
    const year = key.split("_")[1];
    const count = value.length;
    const existingCompany = acc.find((item) => item.name === company);
    if (existingCompany) {
      existingCompany.data.push(count);
    } else {
      acc.push({ name: company, data: [count] });
    }
    return acc;
  }, []);

  const TypeOfNoticeYear = {};

  filteredDataView.forEach((item) => {
    const key = `${item.Company}_${item.Year}`;
    if (!TypeOfNoticeYear[key]) {
      TypeOfNoticeYear[key] = [];
    }
    if (!TypeOfNoticeYear[key].includes(item["Case ID"])) {
      TypeOfNoticeYear[key].push(item["Case ID"]);
    }
  });

  const resultTypeOfNoticeYear = Object.entries(TypeOfNoticeYear).map(
    ([key, value]) => ({
      company: key.split("_")[0],
      year: key.split("_")[1],
      count: value.length,
    })
  );

  const StateWiseAnalysis = {};
  filteredDataView.forEach((item) => {
    if (!StateWiseAnalysis[item.state]) {
      StateWiseAnalysis[item.state] = new Set();
    }
    StateWiseAnalysis[item.state].add(item["Case ID"]);
  });

  const resultStateWise = Object.entries(StateWiseAnalysis).map(
    ([name, cases]) => ({
      name,
      count: cases.size,
    })
  );

  // const sortingByname = (fieldName) => {
  //   console.log("sorting...");
  //   const sortedData = [...filteredData].sort((a, b) => {
  //     if (sortOrder === "asc") {
  //       return a[fieldName].localeCompare(b[fieldName]);
  //     } else {
  //       return b[fieldName].localeCompare(a[fieldName]);
  //     }
  //   });

  //   setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  //   setFilteredData(sortedData);
  // };

  const sortingByname = (fieldName, sortOrder) => {
    const sortedData = [...filteredData].sort((a, b) => {
      if (sortOrder === "asc") {
        return a[fieldName].localeCompare(b[fieldName]);
      } else {
        return b[fieldName].localeCompare(a[fieldName]);
      }
    });

    // Update the sort order for the clicked column
    const newSortOrders = {
      ...sortOrders,
      [fieldName]: sortOrder === "asc" ? "desc" : "asc",
    };
    setSortOrders(newSortOrders);
    setFilteredData(sortedData);
  };



  function formatDate(dateString) {
    // Parse the date string into a Date object
    const date = new Date(dateString);
    
    // Get the day, month, and year
    const day = date.getDate();
    const monthIndex = date.getMonth();
    const year = date.getFullYear();
  
    // Array of month names
    const monthNames = [
      "Jan", "Feb", "Mar",
      "Apr", "May", "Jun", 
      "Jul", "Aug", "Sep", 
      "Oct", "Nov", "Dec"
    ];
  
    // Return the formatted date string
    return `${day} ${monthNames[monthIndex]}, ${year}`;
  }

  const sortingByDate = (fieldName, sortOrder) => {
    const sortedData = [...filteredData].sort((a, b) => {
      // Parse dates manually (assuming "M/D/YYYY" format)
      const dateA = parseDate(a[fieldName]);
      const dateB = parseDate(b[fieldName]);
  
      // Compare dates based on parsed values
      if (sortOrder === "asc") {
        return dateA - dateB;
      } else {
        return dateB - dateA;
      }
    });
  
    // Update the sort order for the clicked column
    const newSortOrders = {
      ...sortOrders,
      [fieldName]: sortOrder === "asc" ? "desc" : "asc",
    };
    setSortOrders(newSortOrders);
    setFilteredData(sortedData);
  };

  const parseDate = (dateString) => {
    const parts = dateString.split('/');
    // Ensure parts are in correct format (month/day/year)
    const day = parseInt(parts[1], 10);
    const month = parseInt(parts[0], 10) - 1; // Month is zero-indexed
    const year = parseInt(parts[2], 10);
    return new Date(year, month, day);
  };
  


  return (
    <div id="dataroom-main" style={{ overflow: "auto" }}>
      <Box className="mainLayout" sx={{ display: "flex", overflow: "auto" }}>
        <MainLayout dataItems={data} setCompany={setCompany} />

        <Box component="main" sx={{ flexGrow: 1, p: 1 }}>
          <DrawerHeader />
          <DrawerHeader style={{ minHeight: "0px", paddingTop: "3px" }} />

          <div id="mainHome" style={{ overflowY: "auto" }}>
            {loading ? (
              <>
                <Loading />
              </>
            ) : (
              <>
                {!data && <>Loading...</>}

                {data && (
               <div style={{position:"relative"}}>

               <span style={{position:"absolute",right:"0px",top:"70px"}} class="subHeaderText">
                                         Last Refresh on 14th Sept 2024
                                       </span>                    <div class="headerItems">
                      <div>
                        <h2 class="headerText">GST Notice Dekho</h2>
                        <p class="subHeaderText">
                          View Additional Notices and Orders
                        </p>
                      </div>
                      <div class="main-section-1">
                        <Link to="/normal/normalnotices">
                          <div class="custom-Total-case-id-2">
                            Click here to view Normal Notices and Orders
                          </div>
                        </Link>

                        <div class="custom-Total-case-id ">
                          Total Case ID : {search(data).length}
                        </div>

                        <ReportGenerate
                          excelData={search(data)}
                          fileName={`Advanced_Notices${new Date().getTime()}`}
                          sheetName="Advanced Notices"
                        />
                      </div>
                    </div>

                    <div class="main-section-2">
                      <div>
                        <div class="section-class-2-grid-layout">
                         
                          {/* <div class="form-group">
                            <label class="company" for="old-new">
                              Old/New
                            </label>
                            <select
                              onChange={(e) => setOldnewData(e.target.value)}
                              id="old-new"
                              name="old-new"
                            >
                              <option value="">All</option>
                              {oldnew.map((item, index) => (
                                <option  key={index} value={item}>
                                 <span style={{textTransform:"capitalize"}}>{item}</span> 
                                </option>
                              ))}
                            </select>
                          </div> */}

                       


                          <div class="form-group">
                            <label class="company" htmlFor="company">
                              Company
                            </label>
                            <Select
                              isMulti
                              options={UniqueFilterByCompany.map((item) => ({
                                value: item,
                                label: item,
                              }))}
                              onChange={(selectedOptions) =>
                                setCompany(
                                  selectedOptions.map((option) => option.value)
                                )
                              }
                              placeholder="All"
                            />
                          </div>

                          <div className="form-group">
                            <label className="Year" htmlFor="Year">
                              Year
                            </label>
                            <Select
                              isMulti
                              options={UniqueFilterByYearsData.map((item) => ({
                                value: item,
                                label: item,
                              }))}
                              onChange={(selectedOptions) =>
                                setYear(
                                  selectedOptions.map((option) => option.value)
                                )
                              }
                              placeholder="All"

                            />
                          </div>

                          <div className="form-group">
                            <label className="Month" htmlFor="Month">
                              Month
                            </label>
                            <Select
                              isMulti
                              options={UniqueFilterByMonth.map((item) => ({
                                value: item,
                                label: item,
                              }))}
                              onChange={(selectedOptions) =>
                                setMonth(
                                  selectedOptions.map((option) => option.value)
                                )
                              }
                              placeholder="All"

                            />
                          </div>

                          <div className="form-group">
                            <label className="State" htmlFor="State">
                              State
                            </label>
                            <Select
                              isMulti
                              options={UniqueFilterByState.map((item) => ({
                                value: item,
                                label: item,
                              }))}
                              onChange={(selectedOptions) =>
                                setState(
                                  selectedOptions.map((option) => option.value)
                                )
                              }
                              placeholder="All"

                            />
                          </div>

                          <div className="form-group">
                            <label className="Status" htmlFor="Status">
                              Status
                            </label>
                            <Select
                              isMulti
                              options={UniqueFilterByStatus.map((item) => ({
                                value: item,
                                label: item,
                              }))}
                              onChange={(selectedOptions) =>
                                setStatus(
                                  selectedOptions.map((option) => option.value)
                                )
                              }
                              placeholder="All"

                            />
                          </div>

                          <div className="form-group">
                            <label className="RefID" htmlFor="RefID">
                              Ref ID
                            </label>
                            <Select
                              isMulti
                              options={UniqueFilterByRef.map((item) => ({
                                value: item,
                                label: item,
                              }))}
                              onChange={(selectedOptions) =>
                                setRefID(
                                  selectedOptions.map((option) => option.value)
                                )
                              }
                              placeholder="All"

                            />
                          </div>

                          <div className="form-group">
                            <label className="caseID" htmlFor="caseID">
                              Case ID
                            </label>
                            <Select
                              isMulti
                              options={UniqueFilterByCase.map((item) => ({
                                value: item,
                                label: item,
                              }))}
                              onChange={(selectedOptions) =>
                                setCaseID(
                                  selectedOptions.map((option) => option.value)
                                )
                              }
                              placeholder="All"

                            />
                          </div>

                          <div class="form-group">
                            <label class="company" htmlFor="company">
                              Old/New
                            </label>
                            <Select
                              isMulti
                              options={oldnew.map((item) => ({
                                value: item,
                                label: item,
                              }))}
                              onChange={(selectedOptions) =>
                                setOldnewData(
                                  selectedOptions.map((option) => option.value)
                                )
                              }
                              placeholder="All"
                            />
                          </div>
                          
                        </div>
                      </div>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          alignContent: "center",
                          marginTop: "0px",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div>
                          <ReactPaginate
                            previousLabel={"Previous"}
                            nextLabel={"Next"}
                            breakLabel={"..."}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={3}
                            onPageChange={handelepageclick}
                            containerClassName={"pagination"}
                            pageClassName={"page-item"}
                            pageLinkClassName={"page-link"}
                            previousClassName={"page-item"}
                            previousLinkClassName={"page-link"}
                            nextClassName={"page-item"}
                            nextLinkClassName={"page-link"}
                            breakClassName={"page-item"}
                            breakLinkClassName={"page-link"}
                            activeClassName={"active"}
                            forcePage={currentPage - 1}
                          />
                        </div>
                      </div>

                      <>
                        <table id="dataroom">
                          <thead>
                            <tr>
                              <th
                                style={{ width: "12%" }}
                                onClick={() =>
                                  sortingByname("Company", sortOrders.Company)
                                }
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  Company{" "}
                                  {sortOrders.Company === "asc" ? (
                                    <FaSortAlphaDown />
                                  ) : (
                                    <FaSortAlphaUp />
                                  )}
                                </span>
                              </th>
                              <th
                                style={{ width: "12%" }}
                                onClick={() =>
                                  sortingByname("state", sortOrders.state)
                                }
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  State{" "}
                                  {sortOrders.state === "asc" ? (
                                    <FaSortAlphaDown />
                                  ) : (
                                    <FaSortAlphaUp />
                                  )}
                                </span>
                              </th>
                              <th
                                style={{ width: "12%" }}
                                onClick={() =>
                                  sortingByname(
                                    "Case ID",
                                    sortOrders["Case ID"]
                                  )
                                }
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  Case ID{" "}
                                  {sortOrders["Case ID"] === "asc" ? (
                                    <FaSortAlphaDown />
                                  ) : (
                                    <FaSortAlphaUp />
                                  )}
                                </span>
                              </th>
                             
                              <th
                                style={{ width: "14%" }}
                                onClick={() =>
                                  sortingByDate(
                                    "Date of Issuance",
                                    sortOrders["Date of Issuance"]
                                  )
                                }
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  Date Of Issuance{" "}
                                  {sortOrders["Date of Issuance"] === "asc" ? (
                                    <FaSortAlphaDown />
                                  ) : (
                                    <FaSortAlphaUp />
                                  )}
                                </span>
                              </th>
                              <th
                                style={{ width: "18%" }}
                                onClick={() =>
                                  sortingByname(
                                    "Type of Notice/Order",
                                    sortOrders["Type of Notice/Order"]
                                  )
                                }
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  Type Of Notice/Order{" "}
                                  {sortOrders["Type of Notice/Order"] ===
                                  "asc" ? (
                                    <FaSortAlphaDown />
                                  ) : (
                                    <FaSortAlphaUp />
                                  )}
                                </span>
                              </th>
                              <th
                                style={{ width: "12%" }}
                                onClick={() =>
                                  sortingByname("Status", sortOrders.Status)
                                }
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  Status{" "}
                                  {sortOrder.Status === "asc" ? (
                                    <FaSortAlphaDown />
                                  ) : (
                                    <FaSortAlphaUp />
                                  )}
                                </span>
                              </th>
                              <th
                                style={{ width: "20%" }}
                                onClick={() =>
                                  sortingByname(
                                    "Description",
                                    sortOrders.Description
                                  )
                                }
                              >
                                <span
                                  style={{
                                    display: "flex",
                                    gap: "10px",
                                    alignContent: "center",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                >
                                  Description{" "}
                                  {sortOrders.Description === "asc" ? (
                                    <FaSortAlphaDown />
                                  ) : (
                                    <FaSortAlphaUp />
                                  )}
                                </span>
                              </th>
                            </tr>

                            <>
                              {currentData.map((item, index) => {
                                return (
                                  <tr key={index}>
                                    <td style={{textAlign:"center"}}>{item.Company}</td>
                                    <td style={{textAlign:"center"}}>{item.state}</td>
                                    <td style={{textAlign:"center"}}>{item["Case ID"]}</td>
                                    <td style={{textAlign:"center"}}>{formatDate(item["Date of Issuance"])}</td>
                                    <td style={{textAlign:"center"}}>{item["Type of Notice/Order"]}</td>
                                    <td>{item.Status}</td>
                                    <td>{item["Description"]}</td>
                                  </tr>
                                );
                              })}
                            </>
                          </thead>
                        </table>
                      </>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default Home;
